<template>
  <v-container>
    <h1 v-html="$t('search_segmentation_forms_title')"></h1>
    <v-row class="row search spacer-md">
      <v-col xs="12" sm="6" md="3">
        <search-dairy-segmentation ref="search-dairy-segmentation" @search="search" />
      </v-col>
      <v-col xs="12" sm="6" md="3">
        <search-segmentation-forms ref="search-segmentation-forms" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" :loading="loading" @click="search"><span v-html="$t('search_segmentation_forms_search')" /></v-btn>
    <v-btn color="secondary" class="margin-left" @click="reset"><span v-html="$t('search_segmentation_forms_reset')" /></v-btn>
    <v-menu
      offset-y
      v-if="
        $privileges.has({
          path: '/segmentationForms',
          permission: 'allDomains'
        })
      "
    >
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="formular" color="secondary" v-bind="props">
          <span v-html="$t('create_manually')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="createNewForm" id="create_manually">
          <v-list-item-title v-html="$t('create_manually')"></v-list-item-title>
        </v-list-item>
        <v-list-item @click="deleteManually" id="search_segmentation_forms_delete_manually">
          <v-list-item-title v-html="$t('search_segmentation_forms_delete_manually')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-data-table-server
      show-select
      v-model="elementToDelete"
      select-strategy="single"
      :headers="fields"
      :items="items"
      class="zero-width"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :multi-sort="true"
      :items-per-page-options="$itemsPerPageOptions"
      density="compact"
    >
      <template v-slot:[`item.dairyIdent`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.dairyId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.dairyIdent }}
          </router-link>
        </template>
        <template v-else>
          {{ item.dairyIdent }}
        </template>
      </template>
      <template v-slot:[`item.dairy.addressName1`]="{ item }">
        <template v-if="$privileges.has({ path: '/legalEntities', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_legal_entities_edit',
              params: { id: item.dairy.id, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.dairy.addressName1 }}
          </router-link>
        </template>
        <template v-else>
          {{ item.dairy.addressName1 }}
        </template>
      </template>
      <template v-slot:[`item.validUntil`]="{ item }">
        <template v-if="item.validUntil">
          {{ $formatDate(item.validUntil) }}
        </template>
      </template>
      <template v-slot:[`item.versionNo`]="{ item }">
        <template v-if="item.versionNo == 1"> </template>
        <template v-if="item.versionNo > 1">
          {{ item.versionNo - 1 }}
        </template>
      </template>
      <template v-slot:[`item.responsibilityGroup`]="{ item }">
        <template v-if="item.responsibilityGroup">
          <!-- {{ responsibilityGroup.filter((el) => el.name == item.responsibilityGroup)[0]['name' + upLangKey] }} -->
          {{ translateResponsabilityGroup(item.responsibilityGroup) }}
        </template>
      </template>
      <template v-slot:[`item.statusSortOrder`]="{ item }">
        {{ item['status' + this.$getUpLangKey()] }}
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <router-link :to="{ name: 'segmentationForms_edit', params: { id: item.id } }" class="tableaction link">
          <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
        </router-link>
      </template>
    </v-data-table-server>
  </v-container>
</template>
<script lang="ts">
import { apiURL, emitter } from '@/main'
import { Term } from '@/services/term'
import { showError } from '@/services/axios'
import _ from 'lodash'
import searchDairySegmentation from '@/components/searchCards/searchDairySegmentation.vue'
import SearchSegmentationForms from '@/components/searchCards/searchSegmentationForms.vue'
import { authService } from '@/services/auth.service'

import { DTSHeader } from '@/services/BackendService'
import { useSearchSegmentationFormStore } from '@/store/SearchSegmentationFormStore'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import type { components } from '@/lib/api/v1.d.ts'
type SegmentationFormList = components['schemas']['SegmentationFormList']
import { useResponsibilityGroupsStore } from '@/store/enums/ResponsibilityGroupsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationForms_read',
  data() {
    return {
      items: [] as SegmentationFormList[],
      totalElements: 0,
      elementToDelete: [],
      loading: false,
      fields: [
        {
          title: '',
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // MBH-ID
        {
          title: this.$t('search_segmentation_forms_mbh_id'),
          key: 'dairyIdent',
          sortable: false,
          align: 'left'
        },
        // Name / Firma
        {
          title: this.$t('search_segmentation_forms_name_company'),
          key: 'dairy.addressName1',
          sortable: false
        },
        // Vorname / Zusatz
        {
          title: this.$t('search_segmentation_forms_family_name_addition'),
          key: 'dairy.addressName2',
          sortable: false
        },
        // Status
        {
          title: this.$t('search_segmentation_forms_status'),
          //key: 'status' + this.$getUpLangKey(),
          key: 'statusSortOrder',
          sortable: true
        },
        // Meldeperiode
        {
          title: this.$t('search_segmentation_forms_valid_until'),
          key: 'validUntil',
          sortable: true
        },
        // Zuständig
        {
          title: this.$t('search_segmentation_forms_responsable_person'),
          key: 'responsibilityGroup',
          sortable: false
        },
        // Korrektur
        {
          title: this.$t('search_segmentation_forms_correction'),
          key: 'versionNo',
          sortable: true,
          align: 'left'
        },
        // Meldungs-ID
        {
          title: this.$t('search_segmentation_forms_id'),
          key: 'id',
          sortable: true,
          align: 'left'
        },
        // Ablage-Nr.
        {
          title: this.$t('search_segmentation_forms_code'),
          key: 'code',
          sortable: true,
          align: 'left'
        }
      ] as DTSHeader[]
    }
  },
  computed: {
    responsibilityGroups() {
      return useResponsibilityGroupsStore().responsibilityGroups
    },
    term(): any {
      return [...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)]
    },
    params() {
      return {
        id: useSearchSegmentationFormStore().SegmentationForm_id,
        status: useSearchSegmentationFormStore().SegmentationForm_status,
        period: useSearchSegmentationFormStore().SegmentationForm_validUntil,
        correction: useSearchSegmentationFormStore().SegmentationForm_correction,
        code: useSearchSegmentationFormStore().SegmentationForm_code
      }
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions({ sort: ['statusSortOrder,asc', 'validUntil,desc'] })
      }
    }
  },
  components: {
    searchDairySegmentation,
    SearchSegmentationForms
  },
  methods: {
    translateResponsabilityGroup(rg) {
      let groups = this.responsibilityGroups.filter((el) => el.name == rg)
      return groups[0][this.$getLangKey()]
    },

    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/segmentationForms/find', {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        // erster Ordnung aufsteigend nach BOM-Status und zweiter Ordnung nach Meldeperiode absteigend sortiert
        // this.items = this.items.sort((firstElement, secondElement) => segmentationStatus[firstElement.status] - segmentationStatus[secondElement.status])
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-dairy-segmentation'].reset()
      this.$refs['search-segmentation-forms'].reset()
    },
    async createNewForm() {
      this.loading = true
      if (this.checkIfCanCreateOrDelete()) {
        emitter.emit('toastError', {
          message: this.$t('segmentation_form_id_and_month_not_selected')
        })
        this.loading = false
        return
      }
      var processingFormId = null
      var pquery = {}
      try {
        if (useSearchSegmentationFormStore().SegmentationForm_validUntil && useSearchDairyStore().Dairy_ident) {
          pquery.yearMonthUntil = useSearchSegmentationFormStore().SegmentationForm_validUntil
          pquery.dairyIdent = useSearchDairyStore().Dairy_ident
        }
        this.items = []
        const response = await this.axios.get(apiURL + '/processingForms/find', { params: pquery })
        const items = await response.data.content
        if (Array.isArray(items) && items.length > 0) {
          processingFormId = items[0].id
        } else {
          this.checkIfItHasAReportingObligation(pquery.dairyIdent)
          this.loading = false
          return
        }
      } catch (e) {
        showError(e)
      }

      try {
        const response = await this.axios.get(apiURL + '/segmentationForms/generateNext', {
          params: {
            processingFormId: processingFormId
          }
        })
        let test = response.data
        if (test.status == 'CREATED') {
          emitter.emit('toastSuccess', {
            message: this.$t('segmentation_form_created_success_message')
          })
        }
        this.load()
      } catch (err) {
        emitter.emit('toastError', {
          message: err.response.data.detail ?? this.$t('toast_error_happened_message')
        })
      } finally {
        this.loading = false
      }
    },
    async checkIfItHasAReportingObligation(dairyIdent) {
      try {
        const response = await this.axios.get(
          apiURL + '/dairies/findByIdent',
          {
            params: { ident: dairyIdent }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (response.data.segmentationReportingObligation == 'NO') {
          emitter.emit('toastError', {
            message: this.$t('segmentation_form_no_reporting_obligation')
          })
          return
        } else {
          emitter.emit('toastError', {
            message: this.$t('segmentation_form_no_tsm_found')
          })
          return
        }
      } catch (e) {
        showError(e)
      }
    },

    async deleteManually() {
      const segmentationFormId = this.elementToDelete[0]
      console.log('this.elementToDelete.dairyId', segmentationFormId)
      this.loading = true
      try {
        const result = await this.axios.delete(apiURL + '/segmentationForms/' + segmentationFormId, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authService.loadedUser.access_token
          }
        })
        if (await result) {
          this.items = this.items.filter(function (element) {
            return element.id != segmentationFormId
          })
          emitter.emit('toastSuccess', {
            message: this.$t('thedatahasbeendeleted')
          })
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    back() {
      this.$router.go(-1)
    },
    $formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    checkIfCanCreateOrDelete() {
      if (null == useSearchDairyStore().Dairy_ident || null == useSearchSegmentationFormStore().SegmentationForm_validUntil) {
        return true
      }
      return false
    }
  },
  watch: {
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  async beforeMount() {
    useResponsibilityGroupsStore().fetchResponsibilityGroups()
    if (this.$route.query.size) this.load()
  }
})
</script>
<style scoped lang="scss">
@import '@/styles/variables.scss';
.disappear {
  display: none;
}

.grey-background {
  background-color: $dbm-light-grey;
  border: 1px solid $dbm-passiv-grey !important;
}

.button-margin-left {
  margin-left: $spacer-xs !important;
}
</style>
